<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'purchaseSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div class="cancel-watermark" v-if="details.status == 1">
            <div class="water-mark-content" v-if="details.paid_amount > 0">
              Cancelled With Refund
            </div>
            <div class="water-mark-content" v-else>Cancelled</div>
          </div>
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Purchase Slip - {{ details.prefix }}{{ details.purchase_no }}
                </h5>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i
                    class="fas fa-print"
                    @click="print(details.id)"
                    title="Print Slip"
                  ></i>
                </div>
                <!-- <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download Slip"></i>
                </div>-->
                <div class="bill-head-icon mr-1" @click="closeSlipModal()">
                  <i class="fas fa-times" title="Close Slip"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center" v-if="slipSettings.organization">
              <p class="font-xxxl mb-0">{{ slipSettings.organization.name }}</p>
              <p class="mb-0 font-print-xxs">
                {{ details.branch.address }}
                <br />
                Tel No: {{ details.branch.phone }} / {{ details.branch.email }}
                <br />
                PAN: {{ slipSettings.organization.pan_vat }}
              </p>
              <hr class="print-hr-margin" />
            </div>
            <!-- col -->
            <!-- <div class="text-center">Tax Invoice</div> -->
            <!-- <hr> -->
            <div class="row">
              <div
                class="col-sm-4 col-lg-4 mg-t-10 mg-sm-t-0 order-2 order-sm-0"
                v-if="details.supplier"
              >
                <label class="content-label mb-1">Purchased from</label>
                <p class="mg-b-0 font-print-xxs">
                  NAME : {{ details.supplier.name }}
                </p>
                <p class="mg-b-0 font-print-xxs">
                  ADDRESS : {{ details.supplier.address }}
                </p>
                <p class="mg-b-0 font-print-xxs">
                  PAN : {{ details.supplier.pan_vat_no }}
                </p>
              </div>
              <!-- col -->
              <div
                class="col-sm-4 col-lg-4"
                :class="{ 'offset-md-4': !details.supplier }"
              >
                <p class="text-center font-l m-0">Purchase Slip</p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <div
                class="col-sm-4 col-lg-4 mg-t-sm-15 mg-t-10 order-1 order-sm-0"
              >
                <p class="mg-b-0 text-sm-right font-print-xxs">
                  Purchase ID : {{ details.prefix }}{{ details.purchase_no }}
                  <span v-if="details.shipping_type == 1">- IMPORT</span>
                </p>
                <p
                  class="mg-b-0 text-sm-right font-print-xxs"
                  v-if="details.purchase_order_id"
                >
                  Order ID : {{ details.purchase_order.prefix
                  }}{{ details.purchase_order.purchase_order_no }}
                </p>
                <p class="mg-b-0 text-sm-right font-print-xxs">
                  DATE : {{ details.date }}
                </p>
                <p
                  class="mg-b-0 text-sm-right font-print-xxs"
                  v-if="details.chalan_no"
                >
                  Chalan No : {{ details.chalan_no }}
                </p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3 font-print-xxs">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N.</th>
                    <th scope class="wd-40p">Particular</th>
                    <th scope class="wd-20p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-10p">Disc.</th>
                    <th scope class="wd-15p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>{{ item.item.name }}</td>
                    <td>
                      {{ item.quantity }}
                      <span class>{{ item.item.unit_type.title }}</span>
                    </td>
                    <td>{{ item.purchase_rate }}</td>
                    <td>
                      {{ parseDigitForSlip(item.purchase_discount_amount) }}
                    </td>
                    <td class="tx-right">
                      {{ parseDigitForSlip(item.total) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="
                  col-sm-7 col-lg-7
                  print-sm-12 print-lg-4
                  order-2 order-sm-0
                  mg-t-5 mg-sm-t-0
                "
              >
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 mb-0 mb-sm-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p
                    class="mg-b-0 font-print-xxs"
                    v-if="details.bill_amount > 0"
                  >
                    {{ toWord(details.bill_amount) }} Only.
                  </p>
                </div>
                <div
                  class="table-responsive no-print-sm mt-2 mt-md-0"
                  v-if="details.paid_amount > 0"
                >
                  <table
                    class="
                      table table-bordered
                      mg-b-0
                      table_lr_border
                      color-secondary
                    "
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Payment ID</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span v-if="details.payment_methods">
                            {{ details.payment_methods.title }}
                            <span v-if="details.bank"
                              >- {{ details.bank_detail.bank }}</span
                            >
                          </span>
                          <span v-else> -- </span>
                        </td>
                        <td v-if="details.txn_id">{{ details.txn_id }}</td>
                        <td v-else class="text-center">- -</td>
                        <td>{{ parseDigitForSlip(details.paid_amount) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Shipping-detail -->
                <div
                  class="footer_detail mt-2 mb-0 p-2"
                  v-if="details.vehicle || details.driver || details.contact"
                >
                  <label class="content-label mb-1">Shipping Detail</label>
                  <p class="mg-b-0 font-print-xxs" v-if="details.vehicle">
                    Vehicle No : {{ details.vehicle }}
                  </p>
                  <p class="mg-b-0 font-print-xxs" v-if="details.driver">
                    Driver Name : {{ details.driver }}
                  </p>
                  <p class="mg-b-0 font-print-xxs" v-if="details.contact">
                    Contact No : {{ details.contact }}
                  </p>
                </div>
                <!-- Shipping-detail -->
              </div>
              <!-- col -->
              <div
                class="
                  col-sm-4 col-lg-4
                  print-sm-12 print-lg-4
                  order-1 order-sm-0
                  color-secondary
                  mb-0
                  mt-0
                  font-print-xxs
                "
              >
                <ul class="list-unstyled lh-6 mb-0 print-sm-mg">
                  <li class="d-flex justify-content-between">
                    <span>Bill Amount</span>
                    <span>{{ parseDigitForSlip(details.bill_amount) }}</span>
                  </li>

                  <li class="d-flex justify-content-between">
                    <span>Grand Total</span>
                    <span>{{ parseDigitForSlip(details.paid_amount) }}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />

            <div class="print-mb-3">
              <div class="row">
                <div class="col-sm-9">
                  <div class="footer_note" v-if="details.note">
                    <label class="content-label mb-1 no-print-sm">Note</label>
                    <p class="mb-0 text-center text-sm-left font-print-xxs">
                      {{ details.note }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <p
                    class="mb-0 mt-p-2 text-right font-print-xxs"
                    v-if="slipSettings.purchase_setting"
                  >
                    <span v-if="slipSettings.purchase_setting.user_name == 1"
                      >User: {{ details.user.name }}</span
                    >
                  </p>
                </div>
              </div>
              <div v-if="details.status == 1">
                <hr />
                <div class="footer_note">
                  <label class="content-label mb-1">Cancelation Details</label>
                  <p class="mg-b-0 font-print-xxs">
                    Cancellation Date : {{ details.date }}
                  </p>
                  <p class="mg-b-0 font-print-xxs">
                    Cancellation User :
                    <span
                      v-if="details.status == 1 && !details.canceled_user"
                      >{{ loggedInUserName }}</span
                    >
                    <span v-else>{{ details.canceled_user.name }}</span>
                  </p>
                  <p
                    class="mb-2 text-sm-left font-print-xxs"
                    v-if="details.remarks"
                  >
                    Remark: {{ details.remarks }}
                  </p>
                </div>
                <hr class="print-display-only-block" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "http://localhost:8080/assets/css/cassie.css",
    "http://localhost:8080/assets/css/bootstrap.css",
    "http://localhost:8080/assets/css/changes.css",
  ],
};

Vue.use(VueHtmlToPaper, options);
export default {
  data() {
    return {
      details: [],
      loggedInUserName: "",
      orderCheckout: false,
    };
  },
  computed: {
    ...mapGetters([
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("purchase", ["dataSets", "slipSettings"]),
    vatAbleExtraFields() {
      return this.details.extra_fields.filter((el) => {
        return el.vat == 1;
      });
    },
    nonVatAbleExtraFields() {
      return this.details.extra_fields.filter((el) => {
        return el.vat == 0;
      });
    },
  },
  filters: {
    makeTitle(slug) {
      var words = slug.split("-");

      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }

      return words.join(" ");
    },
  },
  mounted() {
    //what this does actaully is .. it makes no need to refresh manage return page to show the canceled user name.
    let user = JSON.parse(localStorage.getItem("authData"));
    this.loggedInUserName = user.user.name;
    if (this.$route.name == "purchase-order-checkout") {
      this.orderCheckout = true;
    }
  },
  methods: {
    print() {
      window.print();
    },
    closeSlipModal() {
      this.$store.dispatch("modalClose");
      if (this.$route.name == "edit-purchase-by-id") {
        this.$router.push({ name: "purchase-listing" });
      }
      if (this.$route.name == "purchase-order-checkout") {
        this.$router.push({ name: "purchase-order-listing" });
      }
    },
  },
  watch: {
    dataId(value) {
      this.details = this.dataSets.find(function (data) {
        return data.id == value;
      });
    },
  },
};
</script>
<style>
.print-sm-hr {
  display: none;
}
@media print {
  @page {
    size: auto;
    size: portrait;
    margin: 20px 10px;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
  .modal-dialog {
    max-width: 100% !important;
    margin: 0 auto;
  }
  .p {
    color: #000 !important;
  }
  hr {
    border-top: 1px solid rgb(0 0 0 / 60%);
  }
  .table3 {
    color: #000 !important;
  }
  .table3 th {
    border-top: 1px solid rgb(0 0 0 / 60%) !important;
    border-bottom: 1px solid rgb(0 0 0 / 60%) !important;
  }
  .table3 td {
    border-bottom: 1px solid rgb(0 0 0 / 60%);
  }
  .footer_detail {
    border: 1px solid rgb(0 0 0 / 60%) !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid rgb(0 0 0 / 60%) !important;
  }
  .color-secondary {
    color: #000;
  }
  .content-label {
    color: rgba(0, 0, 0, 0.774);
  }
  @media (max-width: 450px) {
    .font-print-xxs {
      font-size: 0.625rem;
    }
    .table3 th {
      font-size: 0.625rem !important;
    }
    .table3 td {
      font-size: 0.625rem !important;
    }
    .print-sm-mg {
      margin-left: 25%;
    }
    .no-print-sm {
      display: none !important;
    }
    .font-xxxl {
      font-size: 1rem !important;
    }
    .print-sm-hr {
      display: block !important;
    }
    .print-hr-margin {
      margin: 5px !important;
    }
  }
  /* @media (max-width: 750px) {
        .modal-dialog {
            max-width: 100% !important;
            margin: 0 auto;
        }
    } */
  /* @media (max-width: 350px) {
        .modal-dialog {
            max-width: 300px !important;
            margin: 0 auto;
        }
    } */
  .modal-dialog-centered {
    display: block;
  }
}
</style>