<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Purchase</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Purchase
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Purchase</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div
        class="col-6 tx-right"
        v-if="checkSinglePermission('create_purchase')"
      >
        <router-link
          :to="{ name: 'new-purchase' }"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          tag="button"
          >New Purchase</router-link
        >
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in slipSettings.branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select
            v-model="filterParams.opening_stock"
            class="filter-input mr-2 mg-t-5"
            @change="selectStock"
          >
            <option value="0" selected>Purchase Stock</option>
            <option value="1">Opening Stock</option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @input="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              placeholder="To"
              class="mr-1 filter-input mg-t-5"
              @input="selectEndDate"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="filterParams.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Purchase ID</th>
              <th
                :class="
                  authData.general_setting.vatable == 1 ? 'wd-20' : 'wd-15'
                "
              >
                Supplier
              </th>
              <th class="wd-10p">Purchase Amt.</th>
              <th class="wd-5p" v-if="authData.general_setting.vatable == 1">
                VAT
              </th>
              <th class="wd-10p" v-if="authData.general_setting.vatable == 1">
                Bill Amt.
              </th>
              <th class="wd-10p">Paid Amt.</th>
              <th
                :class="
                  authData.general_setting.vatable == 1 ? 'wd-15' : 'wd-10'
                "
                v-if="filterParams.opening_stock == 0"
              >
                Payment mode
              </th>
              <th
                :class="
                  authData.general_setting.vatable == 1 ? 'wd-10' : 'wd-5'
                "
                v-if="filterParams.opening_stock == 0"
              >
                Due
              </th>
              <th
                class="wd-10p text-center no-print"
                v-if="
                  checkIfAnyPermission([
                    'view_purchase',
                    'edit_purchase',
                    'cancel_purchase',
                    'create_purchase_return',
                  ])
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataSets.length > 0">
            <tr v-for="(purchase, index) in dataSets" :key="index">
              <th scope="row" class="table-start-item">
                {{ pageSerialNo + index }}
              </th>
              <td>{{ purchase.date }}</td>
              <td>
                {{ purchase.prefix }}{{ purchase.purchase_no }}
                <span class="tx-danger font-s" v-if="purchase.status == 1"
                  >- Canceled</span
                >
              </td>
              <td>
                <span v-if="purchase.supplier">
                  {{ purchase.supplier.name }}
                </span>
              </td>
              <td>{{ parseDigitForSlip(purchase.purchase_amount) }}</td>
              <td v-if="authData.general_setting.vatable == 1">
                {{ parseDigitForSlip(purchase.vat_amount) }}
              </td>
              <td v-if="authData.general_setting.vatable == 1">
                {{ parseDigitForSlip(purchase.bill_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.paid_amount) }}
              </td>
              <td v-if="filterParams.opening_stock == 0">
                <span v-if="purchase.paid_amount != 0">
                  <span v-if="purchase.payment_methods"
                    >{{ purchase.payment_methods.title }}
                    <span v-if="purchase.bank"
                      >- {{ purchase.bank_detail.bank }}</span
                    >
                  </span>
                </span>
                <span v-else>Credit</span>
              </td>
              <td v-if="filterParams.opening_stock == 0">
                {{ parseDigitForSlip(purchase.due_amount) }}
              </td>
              <td
                class="text-center no-print"
                v-if="
                  checkIfAnyPermission([
                    'view_purchase',
                    'edit_purchase',
                    'cancel_purchase',
                    'create_purchase_return',
                  ])
                "
              >
                <a href="javascript:;" class="mr-3" title="View Slip">
                  <i
                    class="fas fa-eye tx-success"
                    @click="view('purchaseSlip', purchase.id)"
                  ></i>
                </a>
                <span v-if="purchase.is_open_stock != 1">
                  <a
                    href="javascript:;"
                    class="mr-3"
                    @click="edit(purchase.id)"
                    v-if="
                      purchase.status != 1 &&
                      checkSinglePermission('edit_purchase')
                    "
                    title="Edit Purchase"
                  >
                    <i class="fa fa-edit"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="mr-3"
                    @click="returnPurchase(purchase.id)"
                    v-if="
                      purchase.status != 1 &&
                      checkSinglePermission('create_purchase_return')
                    "
                    title="Return Purchase"
                  >
                    <i class="fa fa-undo tx-danger"></i>
                  </a>
                  <a
                    href="javascript:;"
                    @click="cancelPurchase(purchase.id)"
                    v-if="
                      purchase.status != 1 &&
                      checkSinglePermission('cancel_purchase')
                    "
                    title="Cancel Purchase"
                  >
                    <i class="fa fa-times-circle tx-danger"></i>
                  </a>
                </span>
                <span v-if="purchase.is_open_stock == 1">
                  <a
                    href="javascript:;"
                    class="mr-3"
                    @click="editOpeningStock(purchase.id)"
                    v-if="
                      purchase.status != 1 &&
                      checkSinglePermission('edit_purchase')
                    "
                    title="Edit Opening Stock"
                  >
                    <i class="fa fa-edit"></i>
                  </a>
                  <a
                    href="javascript:;"
                    @click="cancelOpeningStock(purchase.id)"
                    v-if="
                      purchase.status != 1 &&
                      checkSinglePermission('cancel_purchase')
                    "
                    title="Cancel Opening Stock"
                  >
                    <i class="fa fa-times-circle tx-danger"></i>
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>

          <tbody v-else-if="!loading && dataSets.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <PurchaseSlip
          @printSlip="printSlip"
          v-if="filterParams.opening_stock == 0"
        />
        <OpeningStockSlip
          @printSlip="printSlip"
          v-if="filterParams.opening_stock == 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import PurchaseSlip from "./PurchaseSlip";
import OpeningStockSlip from "../OpeningStock/OpeningStockSlip";
import Services from "./Services/Service";
import Paginate from "vuejs-paginate";
import SearchDayMonthComponent from "../../../shared/SearchDayMonthComponent";
import _ from "lodash";
export default {
  components: {
    PurchaseSlip,
    OpeningStockSlip,
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      loading: true,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      page: 1,
      pageSerialNo: 1,
      errorMessage: "",
      filterParams: {
        pageIndex: 10,
        branch: "",
        opening_stock: 0,
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
      "authData",
      "today",
    ]),
    ...mapGetters("purchase", ["dataSets", "slipSettings"]),
  },
  beforeMount() {
    // get Data
    this.getFilteredData();
    this.pageReload = true;

    Services.ManagePurchase.getSlipPredefinedData()
      .then((response) => {
        this.$store.commit("purchase/setSlipSettings", response.data.data);
      })
      .catch((error) => {
        if (typeof error.response.data.error_message != "undefiend ") {
          if (error.response.data.error_message == "Access Denied.") {
            this.$router.push({ name: "access-denied" });
          }
        }
      });
  },
  methods: {
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectStock() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectSalesType() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      if (this.filterParams.from_date) {
        this.page = 1;
        this.pageSerialNo = 1;
        this.offset = 0;
        this.getFilteredDataWithoutDates();
      }
    },
    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.$store.commit("purchase/setItemSearchLoading", true);
      this.getFilteredDataWithoutDates();
    }, 500),
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        opening_stock: this.filterParams.opening_stock,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        opening_stock: this.filterParams.opening_stock,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.ManagePurchase.getFilteredPurchases(params)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("purchase/setDataSets", value.data);
          this.totalPageCount = value.total_no_of_pages;
          this.$store.commit("purchase/setItemSearchLoading", false);
        })
        .catch((error) => {
          this.$store.commit("purchase/setItemSearchLoading", true);

          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              // this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("purchase/setItemSearchLoading", false);
        });
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    printSlip(id) {
      console.log(id);
      //once the modal is opened popup print window
      window.print();
    },
    returnPurchase(id) {
      this.$router.push({
        name: "specific-purchase-return",
        params: { id: id },
      });
    },
    cancelPurchase(id) {
      this.$swal({
        title: "Do you really want to cancel this purchase ?",
        html: `<label>Remarks </label>
        <textarea id="textarea" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          let remarks = document.getElementById("textarea").value;
          // cancel the sales with remarks
          return Services.ManagePurchase.cancelPurchase(id, {
            remarks: remarks,
          })
            .then((response) => {
              if (
                response.data.success_message ==
                "Purchase item cancelled successfully."
              ) {
                this.dataSets.forEach((item, index) => {
                  if (item.id == id) {
                    this.dataSets[index]["status"] = 1;
                    (this.dataSets[index]["canceled_date"] = this.today),
                      (this.dataSets[index]["remarks"] = remarks);
                  }
                });
              }
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          //poping up the PurchaseSlip modalif
          this.$store.commit("setDataId", result.value.data.id);

          this.$store
            .dispatch("modalWithMode", { id: "purchaseSlip", mode: "read" })
            .then(() => {
              // setTimeout(()=>{
              //   this.printSlip(result.value.data.id);
              // },1000);
            });
        }
      });
    },
    cancelOpeningStock(id) {
      this.$swal({
        title: "Do you really want to cancel this opening stock ?",
        html: `<label>Remarks </label>
        <textarea id="textarea" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          let remarks = document.getElementById("textarea").value;
          // cancel the sales with remarks
          return Services.ManagePurchase.cancelOpeningStock(id, {
            remarks: remarks,
          })
            .then((response) => {
              if (
                response.data.success_message ==
                "Opening Stock cancelled successfully."
              ) {
                this.dataSets.forEach((item, index) => {
                  if (item.id == id) {
                    this.dataSets[index]["status"] = 1;
                    (this.dataSets[index]["canceled_date"] = this.today),
                      (this.dataSets[index]["remarks"] = remarks);
                  }
                });
              }
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          //poping up the PurchaseSlip modalif
          this.$store.commit("setDataId", result.value.data.id);

          this.$store
            .dispatch("modalWithMode", { id: "purchaseSlip", mode: "read" })
            .then(() => {
              // setTimeout(()=>{
              //   this.printSlip(result.value.data.id);
              // },1000);
            });
        }
      });
    },
    edit(id) {
      this.$router.push(`/purchase/manage/${id}`);
    },
    editOpeningStock(id){
      this.$router.push(`/opening-stock/edit/${id}`);
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/purchase/${id}`);
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },

  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>